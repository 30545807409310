export const UserConstants = {
  APIPORT_DEVPORTAL_ORG_ADMIN: 'APIPORT_DevPortal_Org_Admin',
  APIPORT_DEVPORTAL_TSP: 'APIPORT_DevPortal_TSP',
  CORPORATE_ADMINISTRATOR: 'CAMR_Corporate Administrator',
  USER_ADMINISTRATOR: 'CAMR_User Administrator',
  FLEX_INTERNAL_USER: 'SG-APIPORT-DEV-FLEXONBOARDREQUESTUSER',
  FLEX_LEGAL_REVIEWER: 'SG-APIPORT-DEV-FLEXONBOARDLEGALREVIEWER',
  FLEX_TECH_ASSET_OWNER: 'SG-APIPORT-DEV-FLEXONBOARDTECHAPPROVER',
  FLEX_EXTERNAL_CUSTOMER: 'APIPORT_FLEXONBOARDCUSTOMERUSER',
  PUBLIC: 'https://auth.pingone.com'
  
  /** Use below only for local testing */
  //PUBLIC: 'Issuer'
} as const;
